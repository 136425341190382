<template>
  <div class="d-flex flex-row align-content-center p-2">
    <div class="d-flex flex-column">
      <span class="font-weight-bolder font-size-lg">
        {{ item.name }}
      </span>
      <span class="font-weight-bold">
        {{ item.code }}
      </span>
      <span class="font-weight-bold">
        {{ item.email }}
      </span>
      <span class="font-weight-bold">
        {{ item.phone }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonOrganisationSummary",
  props: {
    item: Object,
  },
};
</script>

<style scoped></style>
